/* OtroComponente.css */

.otro-componente-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f8f9fa;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .otro-componente-header {
    margin-bottom: 20px;
  }
  
  .otro-componente-header h1 {
    margin: 0;
    font-size: 2rem;
    color: #333;
  }
  
  .otro-componente-content {
    margin-bottom: 20px;
  }
  
  .otro-componente-content p {
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
  }
  
  .otro-componente-footer {
    text-align: right;
  }
  
  .otro-componente-footer button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .otro-componente-container {
      padding: 15px;
    }
  
    .otro-componente-header h1 {
      font-size: 1.8rem;
    }
  }
  