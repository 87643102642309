/* src/components/PatientList.css */
.patient-list-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .patient-list {
    list-style-type: none;
    padding: 0;
  }
  
  .patient-item {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: #fff;
  }
  
  .patient-name {
    font-weight: bold;
  }
  
  .patient-age {
    margin-left: 10px;
    color: #666;
  }
  