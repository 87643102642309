/* MedicalRecords.css */

.medical-records-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .record-item {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
  }
  
  .record-item h3 {
    margin-top: 0;
    font-size: 1.2rem;
    color: #333;
  }
  
  .record-item p {
    margin-bottom: 5px;
    font-size: 1rem;
    color: #666;
  }
  
  .record-item .description {
    font-size: 0.9rem;
    color: #888;
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .medical-records-container {
      padding: 10px;
    }
  
    .record-item {
      padding: 8px;
    }
  }
  